@import url('https://rsms.me/inter/inter.css');
@import url('froala-editor/css/froala_style.min.css');
@import url('froala-editor/css/froala_editor.pkgd.min.css');
@tailwind base;
@tailwind components;
@tailwind utilities;

.dark .fr-toolbar .fr-command.fr-btn {
  background-color: #fff;
  margin: 2px;
  padding: 2px;
}

.dark .fr-second-toolbar:empty {
  display: none;
}

.dark .fr-toolbar {
  background-color: #121212;
  border-color: #fff;
}

.dark .fr-box.fr-basic .fr-element {
  background-color: #121212;
  color: #fff;
}

.spin-button-none::-webkit-outer-spin-button,
.spin-button-none::-webkit-inner-spin-button,
.spin-button-none[type='number'] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}

.radiobtn {
  position: relative;
  display: block;
  text-align: center;
}

.radiobtn label {
  display: block;
  background: none;
  color: #a855f7;
  border-radius: 5px;
  padding: 10px 20px;
  border: 2px solid #a855f7;
  margin-bottom: 5px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
}

input[type='radio'] {
  display: none;
  position: absolute;
  width: 100%;
  appearance: none;
}

input[type='radio']:checked + label {
  background: #a855f7;
  color: #ffffff;
}

.chatbot {
  position: fixed;
  right: 40px;
  bottom: 110px;
  width: 420px;
  background: #fff;
  overflow: hidden;
  transform: scale(0.5);
  opacity: 0;
  pointer-events: none;
  border-radius: 15px;
  z-index: 999999999999;
  box-shadow: 0 0 128px 0 rgba(0, 0, 0, 0.3),
    0 32px 64px -48px rgba(0, 0, 0, 0.5);
}

.show-chatbot .chatbot {
  transform: scale(1);
  opacity: 1;
  pointer-events: auto;
}

.chatbot header {
  background: #a855f7;
  padding: 16px 0px;
  text-align: center;
  position: relative;
}

.chatbot header span {
  position: absolute;
  right: 20px;
  top: 50%;
  color: #fff;
  cursor: pointer;
  display: none;
  transform: translateY(-50%);
}

.chatbot header h2 {
  color: #fff;
  font-size: 1.4rem;
  margin: 0;
}

.chatbot .chatbox {
  height: 510px;
  overflow-y: auto;
  padding: 30px 20px 70px;
}

.chatbox .chat {
  display: flex;
}

.chatbox .chat p {
  padding: 12px;
  max-width: 75%;
  font-size: 0.95rem;
  border-radius: 10px 10px 0 10px;
  color: #fff;
  background: #a855f7;
}

.chatbox .outgoing {
  margin: 20px 0;
  justify-content: flex-end;
}

.chatbox .incoming p {
  color: #000;
  margin-top: 5px;
  margin-bottom: 8px;
  background: #f2f2f2;
  border-radius: 10px 10px 10px 0px;
}

.chatbot .chat-input {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  gap: 5px;
  background: #fff;
  padding: 8px 8px;
  border-top: 1px solid #ccc;
}

.chat-input textarea {
  height: 55px;
  width: 100%;
  border: none;
  outline: none;
  font-size: 0.95rem;
  resize: none;
  padding: 16px 15px 16px 10px;
}

.chat-input span {
  align-self: flex-end;
  height: 55px;
  line-height: 55px;
  color: #a855f7;
  font-size: 1.35rem;
  cursor: pointer;
  background: #a855f7;
  color: #fff;
  padding: 0 15px;
  border-radius: 5px;
  visibility: hidden;
}

.chat-input textarea:valid ~ span {
  visibility: visible;
}

.chatbot-toggler {
  position: fixed;
  right: 40px;
  bottom: 35px;
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #a855f7;
  color: #fff;
  border-radius: 50%;
  outline: none;
  border: none;
  cursor: pointer;
}

.chatbot-toggler span {
  position: absolute;
}

.show-chatbot .chatbot-toggler span:first-child,
.chatbot-toggler span:last-child {
  opacity: 0;
}

.show-chatbot .chatbot-toggler span:last-child {
  opacity: 1;
}

@media (max-width: 490px) {
  .chatbot {
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .chatbot .chatbox {
    height: 90%;
  }

  .chatbot header span {
    display: block;
  }
}

.pull-indicator {
  position: absolute;
  top: 16px;
  left: 0;
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-lighter);
  transition: transform 0.2s ease-in-out;
  z-index: 10;
}

.pull-indicator svg {
  transition: transform 0.2s ease-in-out;
}

.pull-indicator.flip svg {
  transform: rotate(180deg);
}

/* .rsw-editor {}

.rsw-toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid var(--color-border);
    background-color: var(--color-bg);
    color: var(--color-text);
    font-family: var(--font-family);
    font-size: var(--font-size);
    line-height: var(--line-height);
}

.rsw-dd {
    display: none;
}

.rsw-ce {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 15vh;
    overflow: hidden;
    border-radius: 0.25rem;
    background-color: var(--color-bg);
    color: var(--color-text);
    font-family: var(--font-family);
    font-size: var(--font-size);
    line-height: var(--line-height);
    transition: all 0.2s ease-in-out;
} */
